<template>
  <div class="container">
    <el-empty description="功能开发中" image="https://koala-applet.oss-cn-chengdu.aliyuncs.com/logo/logo.svg" :image-size="200" />
  </div>
</template>


<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>